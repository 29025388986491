<template>
    <div>
        <div v-for="(item,id) in data" :key="item.id">
            <div v-if="item.hasPermission !== false">
                <div>
                    <b-button @click="itemClicked(id, item)"  class="buttonTreeView d-flex justify-content-start align-items-center">
                        <span class="d-inline-flex justify-content-center pr-1" style="width: 20px;">
                            <span v-if="item.visible && item.child">
                                <feather-icon icon="CornerLeftUpIcon" size="15" stroke-width="4" :stroke="color"/>
                            </span>
                            <span v-else-if="item.child">
                                <feather-icon icon="CornerDownRightIcon" size="15" stroke-width="4" :stroke="color"/>
                            </span>
                            <span class="d-inline-block" v-else>
                                <div class="d-flex align-items-center">
                                    <feather-icon icon="CircleIcon" size="7" stroke-width="4" :stroke="color" :fill="color"/>
                                </div>
                            </span>
                        </span>
                        <span class="item text-left" :style="cssProps" :class="{'selectedItem': itemSelectedId === item.id && !item.visible}">
                            <div v-if="!parentId" class="buttonTreeParentView primary-text">
                                {{item.label}}
                            </div>
                            <div v-else class="primary-text">
                                {{item.label}}
                            </div>
                        </span>
                    </b-button>
                </div>
                <div class="pl-3" v-if="item.child && item.hasPermission !== false">
                    <div v-if="item.visible">
                        <div v-if="selectedItems && selectedItems.length > 0">
                            <tree-view @itemSelected="itemSelected" :selectedItems="selectedItems.slice(1)" :parentId="item.id" :data="item.child" :color="color"/>
                        </div>
                        <div v-else>
                            <tree-view @itemSelected="itemSelected" :selectedItems="[]" :parentId="item.id" :data="item.child" :color="color"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script >
    import {BButton} from 'bootstrap-vue'
    import Vue from 'vue'

    export default {
        components: {
            BButton
        },
        name: 'treeView',
        props: {
            data: {
                type: Array
            },
            color: {
                default: 'black'
            },
            parentId:{
                type: String,
                default: ''
            },
            selectedItems: {
                type: Array
            }
        },
        data() {
            return {
                selectedNode: ''
            }
        },
        computed: {
            cssProps() {
                return {
                    '--bg-color': this.color
                }
            },
            itemSelectedId() {
                if (this.selectedItems && this.selectedItems.length > 0) {
                    return this.selectedItems[0]
                }
                return ''
            }
        },
        methods: {
            itemClicked(id) {

                const newItem = this.data[id]

                if (newItem.child && newItem.child.length > 0) {
                    newItem.visible = !this.data[id].visible
                    Vue.set(this.data, id, newItem)
                    this.$emit('itemSelected', [this.parentId, newItem.id])
                    return
                }

                this.$emit('itemSelected', [this.parentId, newItem.id])
            },
            itemSelected(selectedItems) {
                if (this.parentId) {
                    selectedItems.unshift(this.parentId)
                }

                this.$emit('itemSelected', selectedItems)
            }
        },
        created() {
            if (!this.data || this.data.length === 0) {
                return
            }

            // To close all children when parent closes
            for (let i = 0; i < this.data.length; i++) {
                this.data[i].visible = false
            }
        }
    }

</script>

<style lang="scss" scoped>

  .buttonTreeView{
    padding: 0;
    background: none !important;
    border: none !important;
    font-size: 15px;
  }

  .buttonTreeParentView{
    font-size: 16px;
  }

  .buttonTreeView:hover {
    border: none !important;
    box-shadow: none !important;
  }

  .selectedItem{
    background: var(--bg-color) !important;
    border-radius: 18px;
    font-weight: bold;
  }

  .item{
    padding: 8px 16px;
  }
</style>