<template>
    <div>
        <TreeViewComponent :selectedItems="selectedItems" @itemSelected="itemSelected" :data="data" :color="color" />
    </div>
</template>
<script>
    import TreeViewComponent from '@/views/components/TreeView/Components/TreeViewComponent.vue'

    export default {
        components: {
            TreeViewComponent
        },
        data() {
            return {
                selectedItems: []
            }
        },
        props: {
            color: {
                type: String,
                default: 'black'
            },
            data: {
                type: Array
            }

        },
        methods: {
            findItem(items, id) {
                for (let i = 0; i < items.length; i++) {
                    const item = items[i]

                    if (item.id === id) {
                        return JSON.parse(JSON.stringify(item))
                    }

                    if (item.child && item.child.length > 0) {
                        const found = this.findItem(item.child, id)
                        if (found && found.id) {
                            if (found.filters && found.filters.length && item.filters && item.filters.length) {
                                found.filters = found.filters.concat(item.filters)
                            }
                            return found
                        }
                    }
                }

                return null
            },
            itemSelected(selectedItems) {
                this.selectedItems = selectedItems
                const foundItem = this.findItem(this.data, selectedItems.at(-1))
                this.$emit('clicked', selectedItems.at(-1), foundItem)
            }
        }
    }
</script>